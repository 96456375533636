<template>
<b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
        <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
                {{firstName }} {{lastName}}
            </p>
            <span class="user-status">{{ userType }}</span>
        </div>
        <b-avatar size="40" :src="profileImage" variant="light-primary" badge class="badge-minimal" badge-variant="success">
            <feather-icon v-if="!firstName" icon="UserIcon" size="22" />
        </b-avatar>
    </template>

    <!-- <b-dropdown-item :to="{ name: 'pages-profile'}" link-class="d-flex align-items-center">
        <feather-icon size="16" icon="UserIcon" class="mr-50" />
        <span>Profilim</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'chats-page' }" link-class="d-flex align-items-center">
        <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
        <span>Mesajlar</span>
    </b-dropdown-item> -->

    <!-- <b-dropdown-divider /> -->
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Çıkış Yap</span>
    </b-dropdown-item>
</b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
} from 'bootstrap-vue'

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
    },
    setup() {
        const { firstName, lastName, userType, profileImage } = JSON.parse(localStorage.getItem("userData"));
        return {
            firstName,
            lastName,
            userType,
            profileImage
        }
    },
    created() {
        this.userData = JSON.parse(localStorage.getItem('userData'));
        //console.log(this.userData);
    },
    methods: {
        logout() {
            localStorage.removeItem("userData");
            localStorage.removeItem("accessToken");
            this.$router.push({
                name: 'login'
            });
        },
    },
}
</script>
