<template>
<b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header">
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="6">
        <b-row class="breadcrumbs-top">
            <b-col cols="12">
                <h2 class="content-header-title float-left pr-1 mb-0">
                    {{ $route.meta.pageTitle }}
                </h2>
                <div class="breadcrumb-wrapper">
                    <b-breadcrumb>
                        <b-breadcrumb-item to="/">
                            <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
                        </b-breadcrumb-item>
                        <b-breadcrumb-item v-for="item in $route.meta.breadcrumb" :key="item.text" :active="item.active" :to="item.to">
                            {{ item.text }}
                        </b-breadcrumb-item>
                    </b-breadcrumb>
                </div>
            </b-col>
        </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col class="content-header-right text-md-right d-md-block d-none mb-1" md="6" cols="12" v-if="$route.meta.contentRenderer!='sidebar-left'">
        <b-button ref="addBtn" v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-if="$route.meta.isExcellUpload==true" variant="primary" class="btn-icon" style="margin-right:1em" @click="$root.$emit('AddUploadClick')">
            <feather-icon icon="PlusIcon" />
            Excell Olarak Yükle
        </b-button>
        <b-button ref="addBtn" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="btn-icon" style="margin-right:1em" @click="$root.$emit('AddButtonClick')">
            <feather-icon icon="PlusIcon" />
            YENİ KAYIT EKLE
        </b-button>
        <!-- <b-button v-if="$route.meta.isPdfButton==true" ref="addBtn" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" style="margin-right:1em" @click="$root.$emit('AddPdfClick')">
            <feather-icon icon="PlusIcon" />
            PDF'DEN EKLE
        </b-button> -->
        <b-button v-if="$route.meta.isSetupButton==true" ref="addBtn" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon" style="margin-right:1em" @click="$root.$emit('AddSetupClick')">
            <feather-icon icon="GridIcon" />
            HIZLI KURULUM
        </b-button>
        <b-button v-if="$route.meta.exportButton==true" ref="addBtn" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon" style="margin-right:1em" @click="$root.$emit('ExportData')">
            <feather-icon icon="GridIcon" />
            Dışa Aktar
        </b-button>
        <b-button v-if="$route.meta.isSave==true" ref="addBtn" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" class="btn-icon" style="margin-right:1em" @click="$root.$emit('PreviewSaveClick')">
            <feather-icon icon="GridIcon" />
            DÜZENLEMEYİ KAYDET
        </b-button>
        <b-button v-if="$route.meta.isPreviewButton==true" ref="addBtn" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" style="margin-right:1em" @click="$root.$emit('PreviewClick')">
            <feather-icon icon="TargetIcon" />
            ÖNİZLE VE DÜZENLE
        </b-button>
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon">
                    <feather-icon icon="FrownIcon" />
                </b-button>
            </template>

            <b-dropdown-item>
                <feather-icon icon="MessageSquareIcon" size="16" />
                <span class="align-middle ml-50">Hata Bildir</span>
            </b-dropdown-item>

            <b-dropdown-item>
                <feather-icon icon="CheckSquareIcon" size="16" />
                <span class="align-middle ml-50">Öneri Bildir</span>
            </b-dropdown-item>
        </b-dropdown>
    </b-col>
</b-row>
</template>

<script>
import {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    directives: {
        Ripple,
    },
    components: {
        BBreadcrumb,
        BBreadcrumbItem,
        BRow,
        BCol,
        BDropdown,
        BDropdownItem,
        BButton,
    },
}
</script>
